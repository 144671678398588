/* Table wrapper */
.table-wrapper {
  background: #ffffff;
  border-radius: 0.5rem;
  margin: 0;

  input {
    margin: 0;
  }

  .center {
    align-items: center;
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .secondary-title {
    color: #99a3ba;
    display: block;
    font-weight: 400;
    @media (max-width: 800px) {
      font-size: 1.5rem;
    }
  }

  .user-info {
    align-items: center;
    display: flex;
    justify-content: center;

    .icon {
      align-items: center;
      background-color: #d8dfea;
      border-radius: 5rem;
      color: #ffffff;
      display: flex;
      display: none;
      font-size: 1.8rem;
      font-weight: 500;
      height: 4.5rem;
      justify-content: center;
      margin-right: 1rem;
      width: 4.5rem;
    }
  }

  .asset-wrapper {
    max-width: 7rem;
    padding: 1rem 0;
    width: 10rem;
    height: 7rem;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      max-height: 100%;
      max-width: 100%;
      width: auto;
      height: auto;
      border-radius: 0.3rem;
    }
  }

  .asset-title {
    margin: 1rem 0;
    height: calc(100% - 2rem);
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
  }

  .asset-name {
    font-size: 1.5rem;
    font-weight: 600;
  }

  .asset-tags {
    color: #c2c9d9;
    padding-top: 0.5rem;
  }

  .edit-btn {
    background: #ffffff;
    border: 1px solid #e2e9f6;
    border-radius: 0.5rem;
    color: #99a3ba;
    font-size: 1.5rem;
    padding: 1rem 3.5rem;
    text-decoration: none;
    text-transform: capitalize;
    cursor: pointer;

    &:hover {
      color: #657592;
    }
    @media (max-width: 800px) {
      background-color: transparent;
      border: none;
      display: block;
      height: 2rem;
      margin: 0;
      padding: 0;
      position: relative;
      text-indent: -9999rem;
      width: 2rem;

      &:after,
      &:before {
        background-position: center;
        background-repeat: no-repeat;
        background-size: auto 100%;
        content: "";
        display: block;
        height: 2rem;
        left: 0;
        position: absolute;
        top: 0;
        transition: opacity 0.3s;
        width: 2rem;
      }

      &:before {
        background-image: url("../../assets/images/edit-icon.svg");
      }

      &:after {
        background-image: url("../../assets/images/edit-icon-active.svg");
        opacity: 0;
      }

      &:hover {
        &:before {
          opacity: 0;
        }

        &:after {
          opacity: 1;
        }
      }
    }
  }

  .delete-icon {
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: block;
    height: 2rem;
    margin: 0;
    padding: 0;
    position: relative;
    text-indent: -9999rem;
    width: 2rem;

    &:after,
    &:before {
      background-position: center;
      background-repeat: no-repeat;
      background-size: auto 100%;
      content: "";
      display: block;
      height: 2rem;
      left: -1rem;
      position: absolute;
      top: 0;
      transition: opacity 0.3s;
      width: 2rem;
    }

    &:before {
      background-image: url("../../assets/images/delete-icon.svg");
    }

    &:after {
      background-image: url("../../assets/images/delete-icon-active.svg");
      opacity: 0;
    }

    &:hover {
      &:before {
        opacity: 0;
      }

      &:after {
        opacity: 1;
      }
    }
  }

  .delete-icon,
  .edit-btn {
    visibility: hidden;
    @media (max-width: 800px) {
      visibility: visible;
    }
  }

  .empty {
    align-items: center;
    color: #99a3ba;
    display: flex;
    justify-content: center;
    opacity: 0.5;
    padding: 10rem 4rem;
  }

  .loader {
    margin: 8rem 4rem;
  }

  /* Data Table */

  .rdt_Table,
  .rdt_TableBody,
  .rdt_TableHead,
  .rdt_TableHeadRow {
    width: 100%;
  }

  .rdt_TableCol_Sortable {
    color: #c2c9d9;
    font-size: 1.5rem;
    font-weight: 500;
    transition: all 0.3s;

    @media (max-width: 800px) {
      font-size: 1.4rem;
    }

    span {
      font-size: 1rem;
      margin-left: 0.5rem;
      transition: all 0.3s;
    }

    &:hover,
    &:hover:not(:focus) {
      color: #99a3ba;
    }
  }

  .rdt_TableRow {
    &:hover {
      .delete-icon,
      .edit-btn {
        transition: visibility 0.3s;
        visibility: visible;
      }
    }
  }

  .rdt_TableCell {
    &:nth-child(2) {
      font-weight: 600;
    }

    .switcher {
      margin-bottom: 0;
      top: auto;
      margin-top: -15px;
    }

    .yes {
      display: block;
      text-indent: -10000000000rem;
      background-image: url("../../assets/images/check-icon-color.svg");
      width: 2rem;
      height: 2rem;
      background-repeat: no-repeat;
      background-size: 1.5rem;
      background-position: center;
    }

    &:hover {
      overflow: visible;
      word-break: keep-all;

      div:not(.asset-wrapper):not(.asset-title):not(.asset-name):not(.title):first-child {
        word-wrap: break-word;
        background: linear-gradient(
          to right,
          rgba(247, 250, 255, 1) 90%,
          rgba(247, 250, 255, 0) 100%
        );
        hyphens: auto;
        margin-left: -1rem;
        max-width: 32rem;
        min-width: 5rem;
        overflow: visible;
        overflow-wrap: break-word;
        padding: 0.3rem 6rem 0.3rem 1rem;
        position: absolute;
        text-overflow: inherit;
        white-space: pre-line;
        z-index: 10;
      }
    }

    &.short:hover {
      div:first-child {
        position: relative;
        margin-left: 0;
        text-align: center;
        max-width: none;
        min-width: none;
        padding: 0;
        background: transparent;
      }
    }
  }

  @media (max-width: 1200px) {
    .rdt_TableCell,
    .rdt_TableCol {
      max-width: 10rem;
    }

    .rdt_TableHeadRow,
    .rdt_TableRow {
      width: 100%;
      justify-content: space-between;
    }
  }

  @media (max-width: 800px) {
    .rdt_TableCell,
    .rdt_TableCol {
      padding-left: 1rem;
      padding-right: 1rem;
    }

    .asset-wrapper {
      max-width: 10rem;
      width: 10rem;
    }
  }

  .center {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .form-container {
    height: 100%;
    padding: 4rem;
    padding-bottom: 10rem;
    position: relative;
    width: 100%;
    min-height: 50rem;

    @media (max-width: 800px) {
      padding: 4rem 0.5rem 8rem;
    }

    .select {
      .custom__menu {
        z-index: 101;
      }
    }

    .form-row {
      justify-content: flex-start;
      width: 100%;
      flex-wrap: wrap;

      .form-group {
        margin-left: 2rem;
        margin-right: 2rem;
        position: relative;
        width: calc((100% - 12rem) / 3);

        @media (max-width: 1000px) {
          width: calc((100% - 8rem) / 2);
        }

        @media (max-width: 600px) {
          width: calc(100% - 4rem);
        }

        .color-picker {
          width: 100%;

          @media (max-width: 600px) {
            margin-bottom: 0;
          }
        }
      }
    }
  }

  .table-footer .modal-footer {
    background: #ffffff;
    position: absolute;
    z-index: 100;
  }
}
